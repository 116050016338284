import { default as wall_45dashboardi5mBiir3CfMeta } from "/app/pages/[customerId]/projects/[projectId]/[dashboardId]/wall-dashboard.vue?macro=true";
import { default as repository_45info_45detailstLLeQQyUDPMeta } from "/app/pages/[customerId]/projects/[projectId]/[repositoryInfoId]/repository-info-details.vue?macro=true";
import { default as audit_45instancesWtUgwAT6VWMeta } from "/app/pages/[customerId]/projects/[projectId]/audit-instances.vue?macro=true";
import { default as business_45goalsYOdSDMheeHMeta } from "/app/pages/[customerId]/projects/[projectId]/business-goals.vue?macro=true";
import { default as certificatesW0g2gl1MezMeta } from "/app/pages/[customerId]/projects/[projectId]/certificates.vue?macro=true";
import { default as dashboardQUIGtqBSdIMeta } from "/app/pages/[customerId]/projects/[projectId]/dashboard.vue?macro=true";
import { default as ddosFS1HLDXVdpMeta } from "/app/pages/[customerId]/projects/[projectId]/ddos.vue?macro=true";
import { default as deploymentsBbeRc4ymGZMeta } from "/app/pages/[customerId]/projects/[projectId]/deployments.vue?macro=true";
import { default as devices3lNnqR5PJkMeta } from "/app/pages/[customerId]/projects/[projectId]/devices.vue?macro=true";
import { default as endpointsY4VhkYe0qmMeta } from "/app/pages/[customerId]/projects/[projectId]/endpoints.vue?macro=true";
import { default as firewall_45tracking_45lookupaOtqi172hCMeta } from "/app/pages/[customerId]/projects/[projectId]/firewall-tracking-lookup.vue?macro=true";
import { default as firewallk2hBk0372xMeta } from "/app/pages/[customerId]/projects/[projectId]/firewall.vue?macro=true";
import { default as callbackVvQ2UmpryAMeta } from "/app/pages/[customerId]/projects/[projectId]/linked-repositories/callback.vue?macro=true";
import { default as recommendationssvmLuKVoZMMeta } from "/app/pages/[customerId]/projects/[projectId]/recommendations.vue?macro=true";
import { default as settings59h2nMDBREMeta } from "/app/pages/[customerId]/projects/[projectId]/settings.vue?macro=true";
import { default as usageLr0d5KltuIMeta } from "/app/pages/[customerId]/projects/[projectId]/usage.vue?macro=true";
import { default as wall_45dashboardskxTwiuDx8mMeta } from "/app/pages/[customerId]/projects/[projectId]/wall-dashboards.vue?macro=true";
import { default as whitelistsW2OSJUso0DMeta } from "/app/pages/[customerId]/projects/[projectId]/whitelists.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as project_45details3CtL2sppzMMeta } from "/app/pages/project-details.vue?macro=true";
import { default as unauthorizedUnTSWnoGh4Meta } from "/app/pages/unauthorized.vue?macro=true";
export default [
  {
    name: wall_45dashboardi5mBiir3CfMeta?.name ?? "customerId-projects-projectId-dashboardId-wall-dashboard",
    path: wall_45dashboardi5mBiir3CfMeta?.path ?? "/:customerId()/projects/:projectId()/:dashboardId()/wall-dashboard",
    meta: wall_45dashboardi5mBiir3CfMeta || {},
    alias: wall_45dashboardi5mBiir3CfMeta?.alias || [],
    redirect: wall_45dashboardi5mBiir3CfMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/[dashboardId]/wall-dashboard.vue").then(m => m.default || m)
  },
  {
    name: repository_45info_45detailstLLeQQyUDPMeta?.name ?? "customerId-projects-projectId-repositoryInfoId-repository-info-details",
    path: repository_45info_45detailstLLeQQyUDPMeta?.path ?? "/:customerId()/projects/:projectId()/:repositoryInfoId()/repository-info-details",
    meta: repository_45info_45detailstLLeQQyUDPMeta || {},
    alias: repository_45info_45detailstLLeQQyUDPMeta?.alias || [],
    redirect: repository_45info_45detailstLLeQQyUDPMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/[repositoryInfoId]/repository-info-details.vue").then(m => m.default || m)
  },
  {
    name: audit_45instancesWtUgwAT6VWMeta?.name ?? "customerId-projects-projectId-audit-instances",
    path: audit_45instancesWtUgwAT6VWMeta?.path ?? "/:customerId()/projects/:projectId()/audit-instances",
    meta: audit_45instancesWtUgwAT6VWMeta || {},
    alias: audit_45instancesWtUgwAT6VWMeta?.alias || [],
    redirect: audit_45instancesWtUgwAT6VWMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/audit-instances.vue").then(m => m.default || m)
  },
  {
    name: business_45goalsYOdSDMheeHMeta?.name ?? "customerId-projects-projectId-business-goals",
    path: business_45goalsYOdSDMheeHMeta?.path ?? "/:customerId()/projects/:projectId()/business-goals",
    meta: business_45goalsYOdSDMheeHMeta || {},
    alias: business_45goalsYOdSDMheeHMeta?.alias || [],
    redirect: business_45goalsYOdSDMheeHMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/business-goals.vue").then(m => m.default || m)
  },
  {
    name: certificatesW0g2gl1MezMeta?.name ?? "customerId-projects-projectId-certificates",
    path: certificatesW0g2gl1MezMeta?.path ?? "/:customerId()/projects/:projectId()/certificates",
    meta: certificatesW0g2gl1MezMeta || {},
    alias: certificatesW0g2gl1MezMeta?.alias || [],
    redirect: certificatesW0g2gl1MezMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/certificates.vue").then(m => m.default || m)
  },
  {
    name: dashboardQUIGtqBSdIMeta?.name ?? "customerId-projects-projectId-dashboard",
    path: dashboardQUIGtqBSdIMeta?.path ?? "/:customerId()/projects/:projectId()/dashboard",
    meta: dashboardQUIGtqBSdIMeta || {},
    alias: dashboardQUIGtqBSdIMeta?.alias || [],
    redirect: dashboardQUIGtqBSdIMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: ddosFS1HLDXVdpMeta?.name ?? "customerId-projects-projectId-ddos",
    path: ddosFS1HLDXVdpMeta?.path ?? "/:customerId()/projects/:projectId()/ddos",
    meta: ddosFS1HLDXVdpMeta || {},
    alias: ddosFS1HLDXVdpMeta?.alias || [],
    redirect: ddosFS1HLDXVdpMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/ddos.vue").then(m => m.default || m)
  },
  {
    name: deploymentsBbeRc4ymGZMeta?.name ?? "customerId-projects-projectId-deployments",
    path: deploymentsBbeRc4ymGZMeta?.path ?? "/:customerId()/projects/:projectId()/deployments",
    meta: deploymentsBbeRc4ymGZMeta || {},
    alias: deploymentsBbeRc4ymGZMeta?.alias || [],
    redirect: deploymentsBbeRc4ymGZMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/deployments.vue").then(m => m.default || m)
  },
  {
    name: devices3lNnqR5PJkMeta?.name ?? "customerId-projects-projectId-devices",
    path: devices3lNnqR5PJkMeta?.path ?? "/:customerId()/projects/:projectId()/devices",
    meta: devices3lNnqR5PJkMeta || {},
    alias: devices3lNnqR5PJkMeta?.alias || [],
    redirect: devices3lNnqR5PJkMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/devices.vue").then(m => m.default || m)
  },
  {
    name: endpointsY4VhkYe0qmMeta?.name ?? "customerId-projects-projectId-endpoints",
    path: endpointsY4VhkYe0qmMeta?.path ?? "/:customerId()/projects/:projectId()/endpoints",
    meta: endpointsY4VhkYe0qmMeta || {},
    alias: endpointsY4VhkYe0qmMeta?.alias || [],
    redirect: endpointsY4VhkYe0qmMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/endpoints.vue").then(m => m.default || m)
  },
  {
    name: firewall_45tracking_45lookupaOtqi172hCMeta?.name ?? "customerId-projects-projectId-firewall-tracking-lookup",
    path: firewall_45tracking_45lookupaOtqi172hCMeta?.path ?? "/:customerId()/projects/:projectId()/firewall-tracking-lookup",
    meta: firewall_45tracking_45lookupaOtqi172hCMeta || {},
    alias: firewall_45tracking_45lookupaOtqi172hCMeta?.alias || [],
    redirect: firewall_45tracking_45lookupaOtqi172hCMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/firewall-tracking-lookup.vue").then(m => m.default || m)
  },
  {
    name: firewallk2hBk0372xMeta?.name ?? "customerId-projects-projectId-firewall",
    path: firewallk2hBk0372xMeta?.path ?? "/:customerId()/projects/:projectId()/firewall",
    meta: firewallk2hBk0372xMeta || {},
    alias: firewallk2hBk0372xMeta?.alias || [],
    redirect: firewallk2hBk0372xMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/firewall.vue").then(m => m.default || m)
  },
  {
    name: callbackVvQ2UmpryAMeta?.name ?? "customerId-projects-projectId-linked-repositories-callback",
    path: callbackVvQ2UmpryAMeta?.path ?? "/:customerId()/projects/:projectId()/linked-repositories/callback",
    meta: callbackVvQ2UmpryAMeta || {},
    alias: callbackVvQ2UmpryAMeta?.alias || [],
    redirect: callbackVvQ2UmpryAMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/linked-repositories/callback.vue").then(m => m.default || m)
  },
  {
    name: recommendationssvmLuKVoZMMeta?.name ?? "customerId-projects-projectId-recommendations",
    path: recommendationssvmLuKVoZMMeta?.path ?? "/:customerId()/projects/:projectId()/recommendations",
    meta: recommendationssvmLuKVoZMMeta || {},
    alias: recommendationssvmLuKVoZMMeta?.alias || [],
    redirect: recommendationssvmLuKVoZMMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/recommendations.vue").then(m => m.default || m)
  },
  {
    name: settings59h2nMDBREMeta?.name ?? "customerId-projects-projectId-settings",
    path: settings59h2nMDBREMeta?.path ?? "/:customerId()/projects/:projectId()/settings",
    meta: settings59h2nMDBREMeta || {},
    alias: settings59h2nMDBREMeta?.alias || [],
    redirect: settings59h2nMDBREMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/settings.vue").then(m => m.default || m)
  },
  {
    name: usageLr0d5KltuIMeta?.name ?? "customerId-projects-projectId-usage",
    path: usageLr0d5KltuIMeta?.path ?? "/:customerId()/projects/:projectId()/usage",
    meta: usageLr0d5KltuIMeta || {},
    alias: usageLr0d5KltuIMeta?.alias || [],
    redirect: usageLr0d5KltuIMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/usage.vue").then(m => m.default || m)
  },
  {
    name: wall_45dashboardskxTwiuDx8mMeta?.name ?? "customerId-projects-projectId-wall-dashboards",
    path: wall_45dashboardskxTwiuDx8mMeta?.path ?? "/:customerId()/projects/:projectId()/wall-dashboards",
    meta: wall_45dashboardskxTwiuDx8mMeta || {},
    alias: wall_45dashboardskxTwiuDx8mMeta?.alias || [],
    redirect: wall_45dashboardskxTwiuDx8mMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/wall-dashboards.vue").then(m => m.default || m)
  },
  {
    name: whitelistsW2OSJUso0DMeta?.name ?? "customerId-projects-projectId-whitelists",
    path: whitelistsW2OSJUso0DMeta?.path ?? "/:customerId()/projects/:projectId()/whitelists",
    meta: whitelistsW2OSJUso0DMeta || {},
    alias: whitelistsW2OSJUso0DMeta?.alias || [],
    redirect: whitelistsW2OSJUso0DMeta?.redirect || undefined,
    component: () => import("/app/pages/[customerId]/projects/[projectId]/whitelists.vue").then(m => m.default || m)
  },
  {
    name: admin0eiku7Jj1aMeta?.name ?? "admin",
    path: admin0eiku7Jj1aMeta?.path ?? "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    alias: admin0eiku7Jj1aMeta?.alias || [],
    redirect: admin0eiku7Jj1aMeta?.redirect || undefined,
    component: () => import("/app/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: project_45details3CtL2sppzMMeta?.name ?? "project-details",
    path: project_45details3CtL2sppzMMeta?.path ?? "/project-details",
    meta: project_45details3CtL2sppzMMeta || {},
    alias: project_45details3CtL2sppzMMeta?.alias || [],
    redirect: project_45details3CtL2sppzMMeta?.redirect || undefined,
    component: () => import("/app/pages/project-details.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedUnTSWnoGh4Meta?.name ?? "unauthorized",
    path: unauthorizedUnTSWnoGh4Meta?.path ?? "/unauthorized",
    meta: unauthorizedUnTSWnoGh4Meta || {},
    alias: unauthorizedUnTSWnoGh4Meta?.alias || [],
    redirect: unauthorizedUnTSWnoGh4Meta?.redirect || undefined,
    component: () => import("/app/pages/unauthorized.vue").then(m => m.default || m)
  }
]