<template>
   <div
      class="c-filters rounded-t-md p-4 bg-white dark:bg-surface-950 border border-solid border-stone-100 dark:border-surface-500 border-1"
      data-pc-section="header">
      <div class="flex justify-between">
         <div class="flex justify-start gap-3">
            <slot name="start" />
         </div>

         <slot name="end" />
      </div>
   </div>
</template>